import React from 'react'
import styled from 'styled-components'

export const Privacy = (props) => {
  const { firstName, lastName } = props
  return (
    <>
      I, <strong>{firstName} {lastName}</strong>, would like to hear more about the Humana Men's Health program that is being administered by Truepill.  I understand I don’t have to sign this authorization, and Humana can’t condition treatment, payment, enrollment or eligibility of benefits on whether I sign this authorization.
      <br /><br />
      I consent to having Humana call my cell phone for marketing purposes, such as letting me know about new or different offerings that could help me save money on healthcare costs or other out-of- pocket expenses or other Humana offerings such as mail-delivery pharmacy.
      <br /><br />
      Your consent is voluntary and allows Humana to contact you via text messaging, artificial or prerecorded voice messages or automatic dialing. You may contact Humana to change your preferences at any time. Changing your preferences will not affect your eligibility for Humana benefits and enrollment, payment for coverage of services or ability to get treatment. Data use charges and rates from your cellular carrier may apply.
      <br /><br />
      Unless canceled, this approval will expire two years from the agreement date. If you choose to cancel, you must do so in writing by sending your name, address, date of birth and Humana member ID number to Humana MarketPoint, P.O. Box 14706, Lexington, KY 40512-4706. You also understand canceling won’t apply to any personal information already released. I understand Humana also may receive direct or indirect payment from third parties as a result of the sale of certain products.
      <br /><br />
      I give permission to Humana and its affiliates to send my personal information to TruePill.
      <br /><br />
      <h2>Notice of HIPAA Privacy Policies</h2>
      Your information. Your rights. Your responsibilities.
      <br />
      <br />
      This notice describes how medical information about you may be used and disclosed and how you can get access to
      this information. Please review it carefully.
      <h3>Overview of Your Rights</h3>
      You have the right to:
      <ul>
        <li>Get a copy of your paper or electronic medical record </li>
        <li>Request a correction to your paper or electronic medical record </li>
        <li>Request confidential communication </li>
        <li>Ask us to limit the information we share </li>
        <li>Get a list of those with whom we’ve shared your information </li>
        <li>Get a copy of this privacy notice </li>
        <li>Choose someone to act for you </li>
        <li>File a complaint if you believe your privacy rights have been violated </li>
      </ul>
      <h3>Overview of Your Choices</h3>
      You have some choices in the way that we use and share information as we:
      <br />
      <ul>
        <li>Provide disaster relief </li>
        <li>Provide health care </li>
        <li>Market our services and sell your information </li>
        <li>Tell family and friends about your condition </li>
        <li>Raise funds </li>
      </ul>
      <h3>Overview of Our Uses and Disclosures</h3>
      We may use and share your information as we:
      <br />
      <ul>
        <li>Treat you </li>
        <li>Run our organization </li>
        <li>Bill for your services </li>
        <li>Help with public health and safety issues </li>
        <li>Do research </li>
        <li>Comply with the law </li>
        <li>Respond to organ and tissue donation requests </li>
        <li>Work with a medical examiner or funeral director </li>
        <li>Address workers’ compensation, law enforcement, and other government requests </li>
        <li>Respond to lawsuits and legal actions </li>
      </ul>
      <h3>Your Rights in Detail</h3>
      When it comes to your health information, you have certain rights. This section explains your rights and some of
      our responsibilities to help you.
      <br />
      <h4>Get an electronic or paper copy of your medical record</h4>
      <ul>
        <li>
          You can ask to see or get an electronic or paper copy of your medical record and other health information we
          have about you. Ask us how to do this.{' '}
        </li>
        <li>
          We will provide a copy or a summary of your health information, usually within 30 days of your request. We may
          charge a reasonable, cost-based fee.{' '}
        </li>
      </ul>
      <h4>Ask us to correct your medical record</h4>
      <ul>
        <li>
          You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how
          to do this.{' '}
        </li>
        <li>We may say “no” to your request, but we’ll tell you why in writing within 60 days. </li>
      </ul>
      <h4>Request confidential communications</h4>
      <ul>
        <li>
          You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a
          different address.{' '}
        </li>
        <li>We will say “yes” to all reasonable requests. </li>
      </ul>
      <h4>Ask us to limit what we use or share</h4>
      <ul>
        <li>
          You can ask us not to use or share certain health information for treatment, payment, or our operations. We
          are not required to agree to your request, and we may say “no” if it would affect your care.{' '}
        </li>
        <li>
          If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that
          information for the purpose of payment or our operations with your health insurer. We will say “yes” unless a
          law requires us to share that information.{' '}
        </li>
      </ul>
      <h4>Get a list of those with whom we’ve shared information</h4>
      <ul>
        <li>
          You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to
          the date you ask, who we shared it with, and why.{' '}
        </li>
        <li>
          We will include all the disclosures except for those about treatment, payment, and health care operations, and
          certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but
          will charge a reasonable, cost-based fee if you ask for another one within 12 months.{' '}
        </li>
      </ul>
      <h4>Get a copy of this privacy notice</h4>
      <ul>
        <li>
          You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice
          electronically. We will provide you with a paper copy promptly.
        </li>
      </ul>
      <h4>Choose someone to act for you</h4>
      <ul>
        <li>
          If you have given someone medical power of attorney or if someone is your legal guardian, that person can
          exercise your rights and make choices about your health information.{' '}
        </li>
        <li>We will make sure the person has this authority and can act for you before we take any action. </li>
      </ul>
      <h4>File a complaint if you feel your rights are violated</h4>
      <ul>
        <li>
          You can complain if you feel we have violated your rights by contacting us at humana@truepill.com or
          855-645-8114.{' '}
        </li>
        <li>
          You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by
          sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting{' '}
          <a href="www.hhs.gov/ocr/privacy/hipaa/complaints/">www.hhs.gov/ocr/privacy/hipaa/complaints/</a>.{' '}
        </li>
        <li>We will not retaliate against you for filing a complaint. </li>
      </ul>
      <h3>Your Choices in Detail</h3>
      For certain health information, you can tell us your choices about what we share. If you have a clear preference
      for how we share your information in the situations described below, talk to us. Tell us what you want us to do,
      and we will follow your instructions. In these cases, you have both the right and choice to tell us to:
      <ul>
        <li>Share information with your family, close friends, or others involved in your care </li>
        <li>Share information in a disaster relief situation </li>
      </ul>
      If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your
      information if we believe it is in your best interest. We may also share your information when needed to lessen a
      serious and imminent threat to health or safety.
      <br />
      In these cases we never share your information unless you give us written permission:
      <ul>
        <li>Marketing purposes </li>
        <li>Sale of your information </li>
      </ul>
      In the case of fundraising:
      <ul>
        <li>We may contact you for fundraising efforts, but you can tell us not to contact you again.</li>
      </ul>
      <h3>Our Uses and Disclosures in Detail</h3>
      <h4>How do we typically use or share your health information?</h4>
      We typically use or share your health information in the following ways.
      <h4>Treat you</h4>
      We can use your health information and share it with other professionals who are treating you. Example: A doctor
      treating you for an injury asks another doctor about your overall health condition.
      <h4>Run our organization</h4>
      We can use and share your health information to run our practice, improve your care, and contact you when
      necessary. Example: We use health information about you to manage your treatment and services.
      <h4>Bill for your services</h4>
      We can use and share your health information to bill and get payment from health plans or other entities. Example:
      We give information about you to your health insurance plan so it will pay for your services.
      <h3>How else can we use or share your health information?</h3>
      We are allowed or required to share your information in other ways – usually in ways that contribute to the public
      good, such as public health and research. We have to meet many conditions in the law before we can share your
      information for these purposes. For more information see:{' '}
      <a href="www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html">
        www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html
      </a>
      .<h4>Help with public health and safety issues</h4>
      We can share health information about you for certain situations such as:
      <ul>
        <li>Preventing disease </li>
        <li>Helping with product recalls</li>
        <li>Reporting adverse reactions to medications</li>
        <li>Reporting suspected abuse, neglect, or domestic violence</li>
        <li>Preventing or reducing a serious threat to anyone’s health or safety</li>
      </ul>
      <h4>Do research</h4>
      We can use or share your information for health research.
      <br />
      <h4>Comply with the law</h4>
      We will share information about you if state or federal laws require it, including with the Department of Health
      and Human Services if it wants to see that we’re complying with federal privacy law.
      <br />
      <h4>Respond to organ and tissue donation requests</h4>
      We can share health information about you with organ procurement organizations.
      <br />
      <h4>Work with a medical examiner or funeral director</h4>
      We can share health information with a coroner, medical examiner, or funeral director when an individual dies.
      <br />
      <h4>Address workers’ compensation, law enforcement, and other government requests</h4>
      We can use or share health information about you:
      <ul>
        <li>For workers’ compensation claims </li>
        <li>For law enforcement purposes or with a law enforcement official</li>
        <li>With health oversight agencies for activities authorized by law</li>
        <li>
          For special government functions such as military, national security, and presidential protective services
        </li>
      </ul>
      <h4>Respond to lawsuits and legal actions</h4>
      We can share health information about you in response to a court or administrative order, or in response to a
      subpoena.
      <br />
      <h4>Our Responsibilities</h4>
      <ul>
        <li>We are required by law to maintain the privacy and security of your protected health information. </li>
        <li>
          We will let you know promptly if a breach occurs that may have compromised the privacy or security of your
          information.
        </li>
        <li>We must follow the duties and privacy practices described in this notice and give you a copy of it. </li>
        <li>
          We will not use or share your information other than as described here unless you tell us we can in writing.
          If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.{' '}
        </li>
      </ul>
      For more information see:{' '}
      <a href="www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html">
        www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html
      </a>
      <h3>Changes to the Terms of this Notice</h3>
      We can change the terms of this notice, and the changes will apply to all information we have about you. The new
      notice will be available upon request, in our office, and on our web site.
      <h3>Other Instructions for this Notice</h3>
      <ul>
        <li>This notice is effective as of: January 1, 2021 </li>
        <li>
          If you have questions about these laws please contact the privacy officer at privacy@truepill.com or by
          calling 855-645-8114{' '}
        </li>
        <li>We never market or sell personal information.</li>
        <li>
          If applicable, insurance companies are made aware of diagnoses, visit types, lab results and other clinically
          necessary information for continued provision of services. Sometimes, insurance companies may contact the
          patient/client by mail, telephone or other means. In circumstances in which a patient/client is concerned
          about their confidentiality as it relates to their insurance company, it would be prudent for the
          patient/client to pay privately and not utilize their insurance benefits.
        </li>
        <li>
          If we are contacted by emergency room providers who disclose to us your incapacity or emergency circumstances,
          we will first reach out to you and your emergency contact on our file to verify the authenticity of such
          claim. If we are unsuccessful in this outreach, we may disclose protected health information (PHI) to the
          hospital personnel based on a determination using our professional judgment disclosing only information that
          is directly relevant to the outside contact’s involvement in your health care. We would not, however, disclose
          information unrelated to your emergency care. We will also use our professional judgment and our experience
          with common practice to make reasonable inferences of your best interest in allowing a person to pick up
          filled prescriptions, medical supplies, x-rays, or other similar forms of PHI.
        </li>
        <li>
          Appointment reminders: Truepill may use or disclose your name and basic appointment information in providing
          you with appointment reminders (such as e-mails, SMS messages) that may reference the practices of men’s
          health treatment.{' '}
        </li>
      </ul>
    </>
  )
}

const WrappedPrivacy = () => (
  <Wrapper>
    <Privacy />
  </Wrapper>
)

const Wrapper = styled.div`
  grid-column: contentStart/contentEnd;
`

export default WrappedPrivacy
