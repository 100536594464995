import React from 'react'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import Privacy from '../components/Privacy'

const privacyNotes = () => (
  <Layout hideSignUpButton={true}>
    <Privacy />
  </Layout>
)


export default privacyNotes
